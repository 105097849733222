
import { computed, defineComponent, ref } from '@vue/composition-api'

import { mdiEye, mdiEyeOff } from '@mdi/js'
import zxcvbn from 'zxcvbn'
import { required, passwordsMatch } from '@/utils/validation'

const MINIMAL_PASSWORD_SCORE = 1

export default defineComponent({
  name: 'common-new-password-input',
  props: {
    value: {
      type: String,
      required: true,
    },
    horizontal: {
      type: Boolean,
      required: false,
    },
    isRequired: {
      type: Boolean,
      required: false,
    },
  },
  setup: (props, { root }) => {
    const passwordStrength = computed(() => zxcvbn(props.value).score)

    const passwordStrengthRule = (password: string) =>
      zxcvbn(password).score >= MINIMAL_PASSWORD_SCORE || (root.$t('form.validation.passwordStrength') as string)

    const color = computed(
      () => ['error', 'warning', 'warning', 'success', 'success'][Math.floor(passwordStrength.value)]
    )

    return {
      icons: { mdiEye, mdiEyeOff },
      password: ref(props.value),
      repeatPassword: ref(''),
      showPassword: ref(false),
      passwordStrength,
      color,
      required,
      passwordsMatch,
      passwordStrengthRule,
    }
  },
})
